<template>
  <DefaultLayout>
    <FloatingBox class="sm:w-128">
      <h1 class="mb-4">{{$t('home.title')}}</h1>
    </FloatingBox>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '../layouts/Default.vue'
import FloatingBox from '../components/FloatingBox'

export default {
  name: 'Search',
  components: {
    DefaultLayout,
    FloatingBox,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  // metaInfo() {
  //   return {
  //     title: this.$t('home.title'),
  //   }
  // } 
};
</script>
